import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Buttons from "../components/buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image from "../../static/crescendo.png"
import { navigate } from "gatsby-link"
const crescendo = () => (
  <Layout>
    <Seo title="Kahvisi on Löfbergs Crescendo." image={image} />
    <div className="result" id="1">
      <h3> Olet kätevä käsistäsi. </h3>
      <p>
        Olipa haasteena kukkapöydän nikkarointi, villasukan parsiminen tai
        hirsimökin pystytys, sinä sylkäiset käsiisi, käärit hihasi ja ryhdyt
        hommiin. Näppäryydestäsi hyötyvät myös ystävät ja sukulaiset, sillä
        ojennat auttavan kätesi aina, kun sitä tarvitaan.
        <br />
        <br />
        Kahvisi on Löfbergs Crescendo. Voimakas, luonteikas ja erittäin
        tummapaahtoinen.
      </p>
      <StaticImage
        src="../assets/PackshotStill_Crescendo.jpg"
        alt="Löfbergs Crescendo"
      />
      <Buttons
        quote="Kahvini on Löfbergs Crescendo. Voimakas, luonteikas ja erittäin
              tummapaahtoinen."
        url={typeof window !== "undefined" ? window.location.href + "?fb" : ""}
      />
    </div>
    {typeof window !== "undefined"
      ? window.location.href.includes("?fb") && navigate("/")
      : ""}
  </Layout>
)

export default crescendo
